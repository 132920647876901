import $ from "jquery";
import { xml2js } from "xml-js";

window.JAM = window.JAM || {};
window.JAM.pages = window.JAM.pages || {};

window.JAM.pages.getCustomer = (() => {
  const MSISDN_INPUT_SELECTOR = "#msisdn-input";
  const PAGE_SELECTOR = ".app-get-customer";

  const init = () => {
    const $page = $(PAGE_SELECTOR);

    if (!$page.length) {
      return;
    }

    fetchCustomerMsisdn();
  };

  const fetchCustomerMsisdn = () => {
    JAM.components.common.showLoader();

    const xhr = new XMLHttpRequest();

    xhr.open("GET", JAM.vars.SAFARICOM_HE_API_URL, true);

    xhr.onreadystatechange = () => {
      // In local files, status is 0 upon success in Mozilla Firefox
      if (xhr.readyState === XMLHttpRequest.DONE) {
        const status = xhr.status;

        if (status === 0 || (status >= 200 && status < 400)) {
          // The request has been completed successfully
          const responseJson = xml2js(xhr.responseText, {
            compact: true,
            ignoreDeclaration: true,
          });

          const responseCode =
            responseJson["SOAP-ENV:Envelope"]["SOAP-ENV:Body"][
              "ns0:ServiceResponse"
            ]["ns0:ResponseHeader"]["ns0:ResponseCode"]["_text"];

          if (responseCode == 0) {
            const msisdn =
              responseJson["SOAP-ENV:Envelope"]["SOAP-ENV:Body"][
                "ns0:ServiceResponse"
              ]["ns0:Body"]["ns1:MsisdnResponse"]["ns1:MsisdnHash"]["_text"];

            $(MSISDN_INPUT_SELECTOR).val(msisdn);

            handleFetchMsisdnComplete();
          } else {
            handleFetchMsisdnComplete();
          }
        } else {
          // Oh no! There has been an error with the request!
          handleFetchMsisdnComplete();
        }
      }
    };

    xhr.send();
  };

  const handleFetchMsisdnComplete = () => {
    $(PAGE_SELECTOR).find("form").first().trigger("submit");
  };

  return {
    init,
  };
})();

$(function () {
  window.JAM.pages.getCustomer.init();
});
